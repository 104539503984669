/* eslint-disable */
export default class ReportStructure {
  static create({ id, title, subtitle, active }) {
    return {
      id: id,
      steps: [
        {
          id: '1',
          type: 'form',
          title: 'Informações básicas',
          fields: [
            {
              name: 'fazenda',
              type: 'default',
              label: 'Fazenda - Produtor',
              rules: [
                {
                  key: 'required',
                  value: 'true',
                },
                {
                  key: 'disableFarmIsDraft',
                  value: 'true',
                },
              ],
              placeholder: 'Selecione uma fazenda',
              componentType: 'select',
              selectOptions: {
                key: 'CodFazenda',
                value: 'NomeFantasia',
                entity: 'fazenda',
              },
            },
            {
              name: 'dataAtendimento',
              type: 'number-pad',
              label: 'Data do atendimento',
              rules: [
                {
                  key: 'required',
                  value: 'true',
                },
                {
                  key: 'permitirDataFutura',
                  value: 'false',
                },
                {
                  key: 'naoPermitirDataAnterior',
                  value: 'false',
                },
              ],
              placeholder: 'informe a data do atendimento',
              componentType: 'datePiker',
            },
            {
              name: 'horaInicio',
              type: 'numeric',
              label: 'Horário de início',
              rules: [
                {
                  key: 'required',
                  value: 'true',
                },
              ],
              placeholder: 'informe a hora de início',
              componentType: 'timePiker',
            },
            {
              name: 'nomeConsultor',
              type: 'staticValue',
              label: 'Nome do consultor',
              rules: [
                {
                  key: 'required',
                  value: 'true',
                },
              ],
              placeholder: 'Nome do consultor',
              componentType: 'staticValue',
            },
          ],
        },
        {
          id: '2',
          type: 'form',
          title: 'Assinatura do produtor ou responsavel',
          fields: [
            {
              name: 'horaTermino',
              type: 'numeric',
              label: 'Horário de término',
              rules: [
                {
                  key: 'required',
                  value: 'true',
                },
                {
                  key: 'minimumTimePiker',
                  value: [
                    {
                      key: 'minimumDate',
                      value: 'dataAtendimento',
                    },
                    {
                      key: 'minimumTime',
                      value: 'horaInicio',
                    },
                    {
                      key: 'time',
                      value: '04:00:00',
                    },
                  ],
                },
              ],
              placeholder: 'informe a hora de término',
              componentType: 'timePiker',
            },
            {
              name: 'assinaturaProdutorOuResponsavel',
              type: 'default',
              label: 'Assinatura do produtor ou Responsável',
              rules: [
                {
                  key: 'required',
                  value: 'false',
                },
              ],
              fields: [
                {
                  name: 'assinaturaProdutor',
                  type: 'default',
                  label: 'Assinatura do produtor',
                  rules: [
                    {
                      key: 'required',
                      value: 'true',
                    },
                  ],
                  placeholder: 'Produtor',
                  componentType: 'btnAssinatura',
                },
              ],
              fields1: [
                {
                  name: 'nomeResponsavel',
                  type: 'default',
                  label: 'Nome do responsável',
                  rules: [
                    {
                      key: 'required',
                      value: 'true',
                    },
                  ],
                  placeholder: 'nome do responsável',
                  componentType: 'inputText',
                },
                {
                  name: 'assinaturaResponsavel',
                  type: 'default',
                  label: 'Assintatura do responsável',
                  rules: [
                    {
                      key: 'required',
                      value: 'true',
                    },
                  ],
                  placeholder: 'Responsável',
                  componentType: 'btnAssinatura',
                },
              ],
              componentType: 'groupRadioButton',
            },
          ],
        },
      ],
      title: title,
      active: active,
      subtitle: subtitle,
      layoutItem: {
        title: 'fazenda',
        subtitle1: 'dataAtendimento',
        subtitle2: 'numeroVisita',
      },
    }
  }

  static createStep({ step, title, structure }) {
    const existStep = structure.steps.find(s => s.id === `${step}`)

    if (existStep) {
      const index = structure.steps.indexOf(existStep)

      structure.steps.splice(index, 0, {
        id: `${step}`,
        type: 'form',
        title,
        fields: [],
      })

      structure.steps.forEach((s, i) => {
        s.id = `${i + 1}`
      })
    }

    return structure
  }

  static deleteItem({ structure, item }) {
    if (item.id) {
      structure.steps = structure.steps.filter(step => step.id !== item.id)

      structure.steps.forEach((step, index) => {
        step.id = `${index + 1}`
      })

      return structure
    }

    const steps = structure.steps.map(step => {
      const fields = ReportStructure.recursiveDeleteFields(step.fields, item)

      step.fields = fields

      return step
    })

    structure.steps = steps
    return structure
  }

  static recursiveDeleteFields(fields, item) {
    return fields
      .map(field => {
        if (this.isMatchingField(field, item)) {
          return
        }
        this.recursivelyDeleteFieldFromSubFields(field, item)
        return field
      })
      .filter(Boolean)
  }

  static recursivelyDeleteFieldFromSubFields(field, item) {
    if (field.fields) {
      field.fields = this.recursiveDeleteFields(field.fields, item)
    }
    if (field.fields1) {
      field.fields1 = this.recursiveDeleteFields(field.fields1, item)
    }
  }

  static createComponent({ component, structureItem, structure }) {
    if (structureItem.id) {
      structure.steps.map(step => {
        if (step.id === structureItem.id) {
          step.fields.push(component)
        }
        return step
      })

      return structure
    }

    const steps = structure.steps.map(step => {
      const fields = ReportStructure.recursiveAddFields(
        step.fields,
        structureItem,
        component,
      )

      step.fields = fields

      return step
    })

    structure.steps = steps

    return structure
  }

  static updateComponent({ component, structureItem, structure }) {
    const steps = structure.steps.map(step => {
      const fields = ReportStructure.recursiveUpdateFields(
        step.fields,
        structureItem,
        component,
      )

      step.fields = fields

      return step
    })

    structure.steps = steps

    return structure
  }

  static recursiveUpdateFields(fields, structureItem, component) {
    return fields.map(field => {
      if (this.isMatchingField(field, structureItem)) {
        return component
      }
      this.recursivelyUpdateFieldFromSubFields(field, structureItem, component)
      return field
    })
  }

  static recursivelyUpdateFieldFromSubFields(field, structureItem, component) {
    if (field.fields) {
      field.fields = this.recursiveUpdateFields(
        field.fields,
        structureItem,
        component,
      )
    }
    if (field.fields1) {
      field.fields1 = this.recursiveUpdateFields(
        field.fields1,
        structureItem,
        component,
      )
    }
  }

  static recursiveAddFields(fields, structureItem, component) {
    return fields.map(field => {
      if (this.isMatchingField(field, structureItem)) {
        this.addComponentToField(field, component)
      } else {
        this.recursivelyAddComponentToSubFields(field, structureItem, component)
      }
      return field
    })
  }

  static isMatchingField(field, structureItem) {
    return (
      field.name === structureItem.name ||
      (structureItem.id && field.id === structureItem.id)
    )
  }

  static addComponentToField(field, component) {
    if (!field.fields) {
      field.fields = []
    }
    field.fields.push(component)
  }

  static recursivelyAddComponentToSubFields(field, structureItem, component) {
    if (field.fields) {
      return this.recursiveAddFields(field.fields, structureItem, component)
    }
    if (field.fields1) {
      return this.recursiveAddFields(field.fields1, structureItem, component)
    }
  }

  static generateJSONComponent({ form, rules, mask, structure }) {
    const { title, placeholder, name } = form

    let key = name

    if (!key) {
      const timestamp = new Date().getTime()
      const name = title
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-zA-Z0-9]/g, '_')
        .toLowerCase()

      key = `${name}_${timestamp}`
    }

    const data = {
      name: key,
      label: title,
      masks: mask ?? 'default',
      placeholder: placeholder,
      rules: ReportStructure.generateRules({ rules }),
      componentType: structure.componentType,
    }

    if (structure.subFields) {
      data['fields'] = []
    }

    return data
  }

  static generateRules({ rules }) {
    return Object.entries(rules).map(([key, value]) => {
      return {
        key: key,
        value: value,
      }
    })
  }

  static recursiveFields({ fields, callback }) {
    if (!fields || !fields.length) {
      return
    }

    return fields.map(field => {
      if (field?.fields) {
        field.fields = this.recursiveFields({ fields: field.fields, callback })
      }
      if (field?.fields1) {
        field.fields1 = this.recursiveFields({
          fields: field.fields1,
          callback,
        })
      }

      return callback(field)
    })
  }

  static getFieldByKey({ key, structure }) {
    const filds = ReportStructure.getAllFields({ structure })

    return filds.find(field => field?.name === key)
  }

  static getAllFields({ structure }) {
    const fields = []

    structure.steps.forEach(step => {
      this.recursiveFields({
        fields: step.fields,
        callback: field => {
          fields.push(field)
        },
      })
    })

    return fields.filter(Boolean)
  }

  static diff({ initialData, data }) {
    const diff = []

    initialData.steps.forEach((step, index) => {
      const initialFields = step.fields
      const fields = data.steps[index].fields

      this.recursiveDiff({
        initialFields,
        fields,
        diff,
      })
    })

    return diff
  }

  static isModified({ initialData, data }) {
    const jsonInitialData = JSON.stringify(initialData)
    const jsonData = JSON.stringify(data)

    return jsonInitialData !== jsonData
  }

  static recursiveDiff({ initialFields, fields, diff }) {
    initialFields.forEach((initialField, index) => {
      const field = fields[index]

      if (field) {
        if (field.fields) {
          this.recursiveDiff({
            initialFields: initialField.fields,
            fields: field.fields,
            diff,
          })
        }

        if (field.fields1) {
          this.recursiveDiff({
            initialFields: initialField.fields1,
            fields: field.fields1,
            diff,
          })
        }

        if (initialField.name !== field.name) {
          diff.push({
            initialField,
            field,
          })
        }
      }
    })
  }
}
