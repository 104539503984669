export const lastVisitAndSignature = {
  lines: [
    {
      props: 'bt',
      columns: [
        {
          key: 'dataProximaVisita',
          label: 'Data da próxima visita:',
          props: 'c50',
        },
        {
          key: 'horaProximaVisita',
          label: 'Horário da próxima visita:',
          props: 'bl',
        },
      ],
    },
    {
      props: 'bt',
      columns: [
        {
          key: 'assinaturaProdutor',
          type: 'signatureWithName',
          label: 'Assinatura do produtor:',
          props: 'c50 bl center',
          sufixo: 'nomeProdutor',
        },
        {
          key: 'assinaturaConsultor',
          type: 'signatureWithName',
          label: 'Assinatura do consultor:',
          props: 'c50 center',
          sufixo: 'nomeConsultor',
        },
        {
          key: '',
          label: 'Assinatura do Responsável:',
          lines: [
            {
              props: 'linha_row c50',
              columns: [
                {
                  key: 'assinaturaResponsavel',
                  type: 'assinatura',
                  label: 'Assinatura do Responsável:',
                  props: 'c100',
                },
                {
                  key: 'nomeResponsavel',
                  label: 'Nome do Responsável:',
                  props: 'bt c95',
                },
              ],
            },
          ],
          props: 'c50',
        },
      ],
    },
  ],
  title: 'Próxima visita e assinatura',
}

export const basicInformation = {
  lines: [
    {
      props: 'bt',
      columns: [
        {
          key: 'fazenda',
          label: 'Fazenda: ',
          props: 'c50',
        },
        {
          key: 'cidade',
          label: 'Cidade: ',
          props: 'bl',
        },
      ],
    },
    {
      props: 'bt',
      columns: [
        {
          key: 'nomeProdutor',
          label: 'Produtor:',
          props: 'c50',
        },
        {
          key: 'matriculaProdutor',
          label: 'Matrícula:',
          props: 'bl',
        },
      ],
    },
    {
      props: 'bt',
      columns: [
        {
          key: 'nomeConsultor',
          label: 'Consultor:',
          props: 'c50',
        },
        {
          key: 'liderProjeto',
          label: 'Líder do Projeto:',
          props: 'bl',
        },
      ],
    },
    {
      props: 'bt',
      columns: [
        {
          key: 'dataAtendimento',
          label: 'Data do atendimento:',
          props: 'c33',
        },
        {
          key: 'horaInicio',
          label: 'Horário de início:',
          props: 'bl c33',
        },
        {
          key: 'horaTermino',
          label: 'Horário de término:',
          props: 'bl c33',
        },
      ],
    },
  ],
  title: 'Informações básicas',
}
