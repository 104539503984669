import { brazilianReal, percentage, realNumber, timePicker } from './masks'
import {
  defaultValue,
  dependencies,
  futureDate,
  lessThan,
  max,
  maxDate,
  maxLength,
  min,
  minDate,
  minimumTimePiker,
  minLength,
  multiple,
  nullValue,
  required,
  requiredByOtherField,
  requiredOnlyNotConfirm,
  reverseConform,
  size,
} from './rules'
import { number, text } from './types'

export default [
  {
    label: 'Caixa de texto',
    componentType: 'inputText',
    description: 'Campo de texto simples',
    types: [text, number],
    masks: [realNumber, brazilianReal, percentage, timePicker],

    rules: [
      minLength,
      maxLength,
      size,
      lessThan,
      dependencies,
      multiple,
      required,
      nullValue,
      defaultValue,
    ],
  },
  {
    label: 'Texto',
    componentType: 'text',
    description: 'Campo de texto simples',
  },
  {
    label: 'Checkbox',
    description: 'Campo de seleção',
    componentType: 'checkbox',
    rules: [required],
  },
  {
    label: 'Área de texto',
    componentType: 'textArea',
    description: 'Campo de texto com várias linhas',
    rules: [required, minLength, maxLength],
  },
  {
    componentType: 'groupField',
    label: 'Grupo de campos',
    description: 'Grupo de campos',
    subFields: true,
  },
  {
    componentType: 'groupCheckbox',
    label: 'Grupo de checkbox',
    description: 'Grupo de checkbox',
    subFields: true,
    rules: [requiredByOtherField],
  },
  {
    componentType: 'timePiker',
    label: 'Seletor de hora',
    description: 'Seletor de hora e minutos',
    rules: [required, minimumTimePiker],
  },
  {
    componentType: 'datePiker',
    label: 'Seletor de data',
    description: 'Seletor de data',
    rules: [required, minDate, maxDate, futureDate],
  },
  {
    componentType: 'someCheckbox',
    label: 'Seletor de checkbox',
    description: 'Seletor de checkbox',
    subFields: true,
    rules: [min, max, required],

    properties: {
      components: ['checkbox'],
    },
  },
  {
    componentType: 'select',
    label: 'Seletor de opções',
    description: 'Seletor de opções',
    rules: [required],
  },
  {
    componentType: 'confirmGroup',
    label: 'Conforme grupo',
    description: 'Campo de seleção de conformidade ou não conformidade',
    rules: [required, requiredOnlyNotConfirm, reverseConform],
    subFields: true,
  },
  {
    componentType: 'btnAssinatura',
    label: 'Assinatura',
    description: 'Campo de assinatura',
    rules: [required],
  },
  {
    componentType: 'multipleImageSelect',
    label: 'Seleção de imagens',
    description: 'Seleção de imagens',
    rules: [required, max, min],
  },
  {
    componentType: 'dynamicText',
    label: 'Texto dinâmico',
    description: 'Texto dinâmico',
  },
  {
    componentType: 'selection',
    label: 'Seleção',
    description: 'Seleção',
    subFields: true,
  },
  {
    componentType: 'buttonSelect',
    label: 'Botão de seleção',
    description: 'Botão de seleção',
  },
  {
    componentType: 'multiInsertForm',
    label: 'Formulário de inserção múltipla',
    description: 'Formulário de inserção múltipla',
    subFields: true,
  },
  {
    componentType: 'groupRadioButton',
    label: 'Grupo de radio button',
    description: 'Grupo de radio button, seleção condicional de opções',
    subFields: true,
    subFields1: true,
  },
  {
    componentType: 'onlySelectionGroup',
    label: 'Grupo de seleção única',
    description: 'Grupo de seleção única',
    subFields: true,
  },
  {
    componentType: 'multiSelectionGroup',
    label: 'Grupo de seleção múltipla',
    description: 'Grupo de seleção múltipla',
    subFields: true,
  },
  {
    componentType: 'yearPicker',
    label: 'Seletor de ano',
    description: 'Seletor de ano',
    rules: [required],
  },
]
