import Structure from '../Structure'
import { basicInformation, lastVisitAndSignature } from './BasicStructure'

const groupFields = [
  ...Structure.filter(field => field.subFields).map(
    field => field.componentType,
  ),
]

const anexosImages = fields => {
  const imagesFields = fields.map(field => ({
    props: 'bt',
    columns: [
      {
        key: field.name,
        label: field.label,
        type: 'multipleImageSelect',
        lines: [],
        props: field.props ?? 'bt',
      },
    ],
  }))

  return {
    title: 'Imagens em anexo',
    lines: [...imagesFields],
  }
}

function transformField(field) {
  if (groupFields.includes(field.componentType)) {
    // recursive fields

    const transformedFields = field.fields.map(transformField)

    return {
      columns: transformedFields,
      props: '',
    }
  }

  const transformedField = {
    key: field.name,
    label: field.label || field.name,
    props: field.props ?? 'bt',
    ...(field.sufixo ? { sufixo: field.sufixo } : {}),
  }

  if (field.componentType === 'timePiker') {
    transformedField.props = 'c33'
    transformedField.placeholder = field.placeholder || ''
  } else if (field.componentType === 'btnAssinatura') {
    transformedField.type = 'signature'
  } else if (field.componentType === 'groupRadioButton') {
    transformedField.type = 'signatureWithName'
    transformedField.sufixo = field.fields ? 'nomeProdutor' : 'nomeConsultor'
  }

  const calcRule = field.rules.find(rule => rule.key === 'fieldCalculated')
  if (calcRule) {
    transformedField.type = 'calculated'
    transformedField.calculation = calcRule.value
  }

  return [transformedField]
}

function transformStep(step) {
  const transformedLines = []

  if (step.title.toLowerCase().includes('assinatura')) {
    return
  }

  step.fields.forEach(field => {
    const columns = (field.fields || field.fields1 || []).map(transformField)

    if (!field.fields && !field.fields1) {
      transformedLines.push({
        props: field.props || 'bt',
        columns: transformField(field),
      })
      return
    }

    transformedLines.push({
      props: field.props || 'bt',
      columns,
    })
  })

  return {
    title: step.title,
    lines: transformedLines,
  }
}

export function transformReport(report) {
  const structure = JSON.parse(JSON.stringify(report))

  const imagesFields = []

  structure.steps.shift()

  recursiveFields(structure.steps, field => {
    if (field.componentType === 'multipleImageSelect') {
      imagesFields.push(field)

      field.fields = []
    }
  })

  const data = [basicInformation]

  data.push(...structure.steps.map(transformStep).filter(Boolean))

  data.push(lastVisitAndSignature)

  if (imagesFields.length) {
    const result = anexosImages(imagesFields)
    data.push(result)
  }

  return data
}

const recursiveFields = (fields, callback) => {
  fields.forEach(field => {
    callback(field)

    if (field.fields) {
      recursiveFields(field.fields, callback)
    }

    if (field.fields1) {
      recursiveFields(field.fields1, callback)
    }
  })
}
